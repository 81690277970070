.apppage > * {
  box-sizing: border-box;
}
.apppage .container {
  max-width: 1116px;
}
@media (max-width: 991px) {
  .apppage .bigtitle {
    font-size: 40px!important;
    line-height: 48px!important;
  }
}
@media (max-width: 480px) {
  .apppage .bigtitle {
    font-size: 36px!important;
    line-height: 44px!important;
  }
}
.apppage .content .title {
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  color: #171338;
  width: 110%;
  margin: 274px 0 20px;
}
@media (max-width: 991px) {
  .apppage .content .title {
    margin: 174px 0 20px;
  }
}
.apppage .content .description {
  font-size: 20px;
  line-height: 28px;
  color: #171338;
  margin: 0;
  white-space: nowrap;
}
@media (max-width: 450px) {
  .apppage .content .description {
    font-size: 16px;
    line-height: 24px;
  }
}
.apppage .content .link-button {
  width: 196px;
  height: 68px;
  vertical-align: top;
  color: #fff;
  background: linear-gradient(315deg, #0055ff 0%, #4f23c9 100%);
  border-radius: 34px;
  font-size: 20px;
  font-weight: 400;
  line-height: 68px;
  display: inline-block;
  text-align: center;
  margin-top: 56px;
  margin-bottom: 32px;
}
.apppage .content .image {
  margin-top: 116px;
  height: 586px;
  width: 680px;
  position: relative;
  z-index: -1;
}
.apppage .content .image:after {
  position: absolute;
  content: '';
  left: -50px;
  width: 710px;
  height: 674px;
  display: block;
  background: url(/public/dist/f2b5dba52491a09b90dd54be939a143e.png) no-repeat center center;
  background-size: 710px 674px;
}
.apppage .main {
  margin: 160px auto 0;
}
.apppage .main .title {
  font-size: 48px;
  font-weight: bold;
  color: #171338;
  line-height: 56px;
  margin: 0 0 16px;
}
.apppage .main .desc {
  font-size: 16px;
  font-weight: 400;
  color: #171338;
  line-height: 20px;
}
.apppage .main .bg {
  margin-top: 136px;
  width: 100%;
  height: 520px;
  background: linear-gradient(315deg, #0055ff 0%, #6527bb 100%);
}
@media (max-width: 992px) {
  .apppage .main .bg {
    height: 1000px;
  }
}
.apppage .main .bg .box .left {
  position: relative;
}
.apppage .main .bg .box .left .keypoint {
  position: absolute;
  left: 30%;
  bottom: 40%;
}
.apppage .main .bg .box .left .keypoint .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  font-size: 20px;
  color: #fff;
  background-color: #0055FF;
  line-height: 22px;
  cursor: pointer;
}
.apppage .main .bg .box .left .keypoint .icon::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  content: '';
  display: block;
  animation: pulse-shadow--collection 2s 1s linear infinite;
  opacity: 0;
}
.apppage .main .bg .box .left .keypoint .panel {
  width: 240px;
  height: 156px;
  background: rgba(255, 255, 255, 0.94);
  box-shadow: 0px 12px 40px 0px rgba(0, 48, 144, 0.12);
  border-radius: 4px;
  display: none;
  padding: 24px 16px 0;
}
.apppage .main .bg .box .left .keypoint .panel h5 {
  font-size: 16px;
  font-weight: bold;
  color: #0055ff;
  line-height: 24px;
  margin: 0 0 4px;
}
.apppage .main .bg .box .left .keypoint .panel span {
  font-size: 14px;
  font-weight: 400;
  color: #171338;
  line-height: 20px;
}
.apppage .main .bg .box .left .keypoint:hover .panel {
  display: block;
  position: absolute;
  top: -180px;
  left: -100px;
}
.apppage .main .bg .box .left .image {
  max-width: 597px;
  width: 100%;
  height: auto;
  position: relative;
  left: -78px;
  top: -80px;
}
@media (max-width: 992px) {
  .apppage .main .bg .box .left .image {
    left: 0;
  }
}
.apppage .main .bg .box .text {
  margin-top: 88px;
}
.apppage .main .bg .box .text .item {
  margin-bottom: 40px;
}
.apppage .main .bg .box .text .item .subtitle {
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  line-height: 40px;
  margin: 0 0 4px;
}
.apppage .main .bg .box .text .item .desc {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
.apppage .feature {
  background: linear-gradient(360deg, #f8f9fb 0%, #ffffff 100%);
  padding-top: 200px;
}
.apppage .feature .container {
  padding-bottom: 172px;
}
.apppage .feature .title {
  font-size: 48px;
  font-weight: bold;
  color: #171338;
  line-height: 56px;
  margin: 0 0 16px;
}
.apppage .feature .desc {
  font-size: 16px;
  font-weight: 400;
  color: #171338;
  line-height: 20px;
}
.apppage .feature .box {
  margin-top: 56px;
  width: 100%;
  height: 0;
  padding-bottom: 59%;
  display: flex;
  background: rgba(255, 255, 255, 0.94);
  box-shadow: 8px 40px 80px 0px rgba(0, 48, 144, 0.12);
  border-radius: 4px 80px 4px 80px;
  position: relative;
}
@media (max-width: 992px) {
  .apppage .feature .box {
    padding-bottom: 160%;
    display: block;
  }
}
@media (max-width: 660px) {
  .apppage .feature .box {
    padding-bottom: 190%;
  }
}
@media (max-width: 470px) {
  .apppage .feature .box {
    padding-bottom: 230%;
  }
}
.apppage .feature .box .appFeature1,
.apppage .feature .box .appFeature2,
.apppage .feature .box .appFeature3 {
  display: none;
}
.apppage .feature .box .appFeature1.active,
.apppage .feature .box .appFeature2.active,
.apppage .feature .box .appFeature3.active {
  display: block;
}
.apppage .feature .box .text {
  flex: 1;
  width: 50%;
  height: 100%;
}
@media (max-width: 992px) {
  .apppage .feature .box .text {
    width: 100%;
    height: 500px;
  }
}
.apppage .feature .box .text .number {
  width: 64px;
  height: 64px;
  margin: 88px 0 0 48px;
}
.apppage .feature .box .text .subtitle {
  font-size: 48px;
  font-weight: bold;
  color: #171338;
  line-height: 56px;
  margin: 56px 0 30px 48px;
}
@media (max-width: 992px) {
  .apppage .feature .box .text .subtitle {
    font-size: 32px;
  }
}
.apppage .feature .box .text .desc {
  font-size: 16px;
  font-weight: 400;
  color: #171338;
  line-height: 20px;
  margin: 0 36px 0 48px;
}
.apppage .feature .box .text .buttons {
  width: 200px;
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #c1d0e2;
  display: flex;
  position: absolute;
  bottom: 96px;
  left: 48px;
}
@media (max-width: 992px) {
  .apppage .feature .box .text .buttons {
    position: static;
    margin: 50px 0 40px 48px;
  }
}
.apppage .feature .box .text .buttons .arrow {
  width: 40px;
  height: 40px;
  margin: 10px auto;
}
.apppage .feature .box .text .buttons .left {
  flex: 1;
  text-align: center;
  cursor: pointer;
}
.apppage .feature .box .text .buttons .right {
  flex: 1;
  text-align: center;
  background: #0052f9;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
}
.apppage .feature .box .image {
  flex: 1;
  width: 50%;
}
@media (max-width: 992px) {
  .apppage .feature .box .image {
    width: 60%;
    margin: 0 auto;
  }
}
.apppage .feature .box .image .appFeature1 {
  width: 100%;
  height: 0;
  padding-bottom: 130%;
  background: url(/public/dist/9588f8864a32a8ee964e585291678eb7.png) no-repeat center center;
  background-size: 100%;
}
.apppage .feature .box .image .appFeature2 {
  width: 100%;
  height: 0;
  padding-bottom: 130%;
  background: url(/public/dist/003d05144e29e3d97f6ebaac7e6a77d9.png) no-repeat center center;
  background-size: 100%;
}
.apppage .feature .box .image .appFeature3 {
  width: 100%;
  height: 0;
  padding-bottom: 118%;
  background: url(/public/dist/97ae9ac0291ef3beb59ea5278395d7ac.png) no-repeat center center;
  background-size: 100%;
}
.apppage .ipad {
  text-align: left;
  padding: 120px 16px 210px;
}
.apppage .ipad .title {
  font-size: 48px;
  font-weight: bold;
  color: #171338;
  line-height: 56px;
}
.apppage .ipad .desc {
  font-size: 16px;
  font-weight: 400;
  color: #171338;
  line-height: 20px;
}
.apppage .ipad .content {
  position: relative;
  width: 100%;
  margin: 56px auto;
}
.apppage .ipad .content .image {
  height: auto;
  width: 100%;
  margin: 0 auto;
}
.apppage .ipad .appstore {
  margin: 0 auto;
  width: 250px;
  height: 68px;
  background: #171338;
  border-radius: 34px;
  padding: 12px 55px;
  display: block;
}
.apppage .ipad .appstore .image {
  width: 40px;
  height: 40px;
  float: left;
}
.apppage .ipad .appstore .text {
  margin-left: 48px;
}
.apppage .ipad .appstore .text .desc {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  display: block;
  margin: 0;
}
.apppage .ipad .appstore .text .title {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  line-height: 20px;
  display: block;
  margin: 0;
  vertical-align: top;
}
.apppage .trial {
  width: 100%;
  height: 720px;
  text-align: center;
  margin: 80px 0 210px;
  position: relative;
}
.apppage .trial .bg {
  height: 750px;
  background-color: #171338;
  transform: skewY(-3.6deg);
  z-index: 1;
}
.apppage .trial .lines {
  position: absolute;
  width: 800px;
  height: 112px;
  left: 60%;
  top: -112px;
}
.apppage .trial .data {
  position: absolute;
  z-index: 2;
  top: 250px;
  left: 50%;
  transform: translate(-50%, 0);
}
.apppage .trial .data .title {
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
  line-height: 44px;
  margin: 0 0 8px;
}
.apppage .trial .data .desc {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  margin: 0;
}
.apppage .trial .data .buttons {
  margin: 36px auto;
  display: flex;
  width: 532px;
  justify-content: space-between;
}
@media (max-width: 540px) {
  .apppage .trial .data .buttons {
    display: block;
  }
}
.apppage .trial .data .buttons .google,
.apppage .trial .data .buttons .apple {
  width: 250px;
  height: 68px;
  background: #fff;
  border-radius: 34px;
  padding: 12px 45px;
  display: block;
}
@media (max-width: 540px) {
  .apppage .trial .data .buttons .google,
  .apppage .trial .data .buttons .apple {
    margin: 32px auto;
  }
}
.apppage .trial .data .buttons .google .image,
.apppage .trial .data .buttons .apple .image {
  width: 40px;
  height: 40px;
  float: left;
}
.apppage .trial .data .buttons .google .text,
.apppage .trial .data .buttons .apple .text {
  margin-left: 48px;
}
.apppage .trial .data .buttons .google .text .desc,
.apppage .trial .data .buttons .apple .text .desc {
  font-size: 12px;
  font-weight: 400;
  color: #171338;
  line-height: 20px;
  display: block;
  margin: 0;
}
.apppage .trial .data .buttons .google .text .title,
.apppage .trial .data .buttons .apple .text .title {
  font-size: 20px;
  font-weight: bold;
  color: #171338;
  line-height: 20px;
  display: block;
  margin: 0;
  vertical-align: top;
}
